.parentSelector{
    width: 300px;
    border: 1px solid rgb(220,220,220);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
}

.parentSelector__title-container{
    width: 100%;
    background-color: rgba(0,0,0,0.02);
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 5px 5px 5px 10px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
}

.parentSelector__title{
    font-weight: 800;
    text-align: left;
    flex: 1;
}

.parentSelector__button{
    height: 20px;
    width: 20px;
    background-color: white;
    cursor: pointer;
}
.parentSelector__button:hover{
    font-weight: 800;
}


.parentSelector__parent{
    display: flex;
    /* font-weight: 800; */
    width: 100%;
    /* background-color: rgba(0,0,0,0.02); */
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 5px 5px 5px 10px;
    height: 50px;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    cursor: pointer;
}
.parentSelector__parent-selected{
    /* color: rgb(100,150,200); */
    font-weight: 600;
    background-color: rgba(0,0,0,0.05);
}
.parentSelector__parent-key{
    /* font-weight: 800; */
    width: 30px;
    height: 30px;
    border-radius: 4px;
    /* background-color: rgba(0,0,0,0.02); */
    /* border-bottom: 1px solid rgba(0,0,0,0.05); */
    margin: 5px 10px;
}
.parentSelector__text{
    flex: 1
}
.parentSelector__input{
    width: 96px;
    height: 30px;
    line-height: 35px;
    border: none;
    font-size: 16px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
}
input:focus-visible {
    outline: none;
}
.parentSelector__edit{
    font-weight: 600;
    height: 30px;
    border-radius: 4px;
    color: rgba(0,0,0,0.3);
    /* background-color: rgba(0,0,0,0.2); */
    /* border-bottom: 1px solid rgba(0,0,0,0.05); */
    line-height: 30px;
    padding: 0px 10px;
    margin: 5px 0px 5px 5px;
}
.parentSelector__edit:hover{
    background-color: rgba(0,0,0,0.1);
    color: rgba(0,0,0,0.8);
}
.parentSelector__active{
    background-color: rgba(65, 136, 243, 0.87) !important;
    color: rgba(0,0,0,0.8) !important;
}
.parentSelector__active:hover{
    background-color: rgba(38, 107, 211, 0.897);
    color: rgba(0,0,0,0.8);
}