.day {
  border-style: solid;
  border-width: 0px 1px;
  display: inline-block;
  border-color: rgba(0,0,0, 0.5);
  position: "relative" !important;
  /* overflow: hidden; */
  margin-top: 30px;
  margin-right: -1px;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
}
.day_title {
  position: absolute;
  bottom: 100%;
  width: 100%;
  text-align: center;
}

.event {
  border-radius: 5px;
  position: "absolute";
  /* top: 0px; */
  box-sizing: border-box;
}

.day__event-selected{
  filter: brightness(0.8);
}
