.overview {
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
  /* min-height: 100vh; */
  height: -webkit-fill-available;
  position: relative;
}
.overview__title {
  /* position: absolute;
    top: 0px; */
  /* width: 100%;
     */
  /* background-color: rgb(255,255,255); */
  /* left: 50%; */
  /* box-shadow: 0px 0px 10px rgba(0,0,0,0.1); */
  padding: 10px;
  border-radius: 5px;
  /* transform: translate(-50%,0); */
  text-align: center;
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 100;
}
.overview__container {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: -webkit-fill-available;
}

.overview__main {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-top: 60px;
  flex: 1;
  overflow-x: scroll;
  background-color: rgba(119, 119, 119, 0.06);
  /* background-color: green; */
  position: relative;
}
.buyCoffee {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.overview__buttons {
  /* position: absolute; */
  /* top: 120px;
    left: 50%;
    transform: translate(-50%,0); */
  /* width: 160px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
