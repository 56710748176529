.selectedEventOptions{
    width: 300px;
    border: 1px solid rgb(220,220,220);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    height: 180px;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.selectedEventOptions__title-container{
    width: 100%;
    background-color: rgba(0,0,0,0.02);
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 5px 5px 5px 10px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    font-weight: 800;
    text-align: left;
}

.selectedEventOptions__label{
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    padding: 10px;
    /* flex: 1; */
}

.selectedEventOptions__data-container{
    width: 100%;
    height: 30px;
    line-height: 30px;
    /* padding: 10px; */
    display: flex;
}

.selectedEventOptions__data-label{
    font-weight: 600;
    width: 100px;
    text-align: right;
    /* flex: 1; */
}

.selectedEventOptions__data{
    font-weight: 400;
    flex: 1;
    padding-left: 10px;
    text-align: left;
}