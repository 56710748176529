.keyContainer {
  width: 280px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 5px;
  border-radius: 6px;
  margin: 20px auto;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.keyItem {
  position: relative;
  /* width: 100%; */
  height: 30px;
  padding-left: 40px;
  margin: 9px;
  line-height: 30px;
}
.keyItem_color {
  position: absolute;
  left: 0px;

  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 3px;
}
