.calendar {
  /* background-color: rgb(200, 200, 200); */
  /* max-width: 500px; */
  width: 100%;
  overflow-x: auto;
  min-height: 200px;
  /* padding: 0px 40px; */
  box-sizing: border-box;
  text-align: center;
}

.week {
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: nowrap; */

  white-space: nowrap;
  display: inline-block;
}
